import React, { useState, useEffect } from 'react';
import { formatCurrencyEGP, formatDate } from 'common/utils';
import IconButton from 'components/shared/IconButton';
import {
  CheckIcon,
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import TopupService from 'services/topup.service';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { useAuth } from 'context/auth.context';

const statusList = ['Pending', 'Approved', 'Rejected'];
export default function SettingsContainer() {
  // generic state
  const [topupMethods, setTopupMethods] = useState([]);
  const { permissions } = useAuth();

  // handle the request actions
  const [topupToApprove, setTopupToApprove] = useState(null);
  const [topupToReject, setTopupToReject] = useState(null);
  const [rejecttionNote, setRejectionNote] = useState(null);

  // handle the main listing
  const [topupRequests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({
    customerId: '',
    programId: '',
    topupMethodId: '',
    status: 'Pending',
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    TopupService.listMethods()
      .then(data => setTopupMethods(data))
      .catch(error => console.log(error));
  }, []);

  // load the list of topup requests
  const loadRequests = () => {
    setLoading(true);
    TopupService.list(filters)
      .then(data => {
        setRequests(data.items);
        setLoading(false);
        setTotalPages(Math.ceil(data.totalCount / filters.pageSize));
      })
      .catch(error => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadRequests();
  }, [filters]);

  // update the filters
  const updateFilters = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
      page: 0,
    });
  };

  // approve the tpoup request
  const approveRequest = () => {
    TopupService.approve(topupToApprove)
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setTopupToApprove(null);
        loadRequests();
      });
  };

  // reject the tpoup request
  const rejectRequest = () => {
    TopupService.reject(topupToReject, rejecttionNote)
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setTopupToReject(null);
        setRejectionNote(null);
        loadRequests();
      });
  };

  return (
    <div>
      <div className="flex items-center  px-4 py-1">
        <div className="flex">
          <select
            className="c4r__input-gray mr-3"
            value={filters.status}
            onChange={event => {
              updateFilters('status', event.target.value);
            }}
          >
            {statusList.map(el => (
              <option value={el} key={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
        <div className="flex">
          <select
            className="c4r__input-gray ml-3"
            value={filters.topupMethodId}
            onChange={event => {
              updateFilters('topupMethodId', event.target.value);
            }}
          >
            <option value="">ALL</option>
            {topupMethods.map(el => (
              <option value={el.id} key={el.id}>
                {el.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex">
          <input
            type="text"
            className="c4r__input-gray ml-3"
            placeholder="Customer Id..."
            value={filters.customerId}
            onChange={event => {
              updateFilters('customerId', event.target.value);
            }}
          />
        </div>
        <div className="flex">
          <input
            type="text"
            className="c4r__input-gray ml-3"
            placeholder="Program Id..."
            value={filters.programId}
            onChange={event => {
              updateFilters('programId', event.target.value);
            }}
          />
        </div>
        <div className="flex">
          <Button
            onClick={() => {
              setFilters({
                customerId: '',
                programId: '',
                topupMethodId: '',
                status: 'Pending',
                page: 0,
                pageSize: 20,
              });
            }}
            color="red-100"
          >
            Clear Filters
          </Button>
        </div>
      </div>
      <div className="p-4">
        <table className="min-w-full shadow-lg rounded-sm">
          <thead className="p-3">
            <tr className="bg-gray-300 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
              <th className="px-6 py-4">Customer</th>
              <th className="px-6 py-4">Program</th>
              <th className="px-6 py-4">Amount</th>
              <th className="px-6 py-4">Status</th>
              <th className="px-6 py-4">Method</th>
              <th className="px-6 py-4">Created</th>
              {permissions.includes('manage-topup-requests') ? (
                <th className="px-6 py-4">Actions</th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!topupRequests.length ? (
              <tr>
                <td className="px-6 py-3 font-bold text-gray-800 uppercase">
                  {loading ? ' Loading...' : 'No Results...'}
                </td>
              </tr>
            ) : (
              <></>
            )}
            {topupRequests.map(row => (
              <tr key={row.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                  {row.customerName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                  {row.programName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {formatCurrencyEGP(row.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {row.status}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {row.topupMethod}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(new Date(row.created), 'dd MMM, yyyy')}
                </td>
                {permissions.includes('manage-topup-requests') ? (
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <IconButton
                      color="green-400"
                      textColor="white"
                      className="mr-2"
                      disabled={row.status !== 'Pending'}
                      onClick={() => {
                        setTopupToApprove(row.id);
                      }}
                      icon={<CheckIcon className="h-5 w-5 text-white" />}
                    />
                    <IconButton
                      color="red-400"
                      textColor="white"
                      className="mr-2"
                      disabled={row.status !== 'Pending'}
                      onClick={() => {
                        setTopupToReject(row.id);
                      }}
                      icon={<XIcon className="h-5 w-5 text-white" />}
                    />
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pt-5 text-center">
          <IconButton
            color="gray"
            disabled={filters.page === 0}
            onClick={() => {
              setFilters({ ...filters, page: filters.page - 1 });
            }}
            icon={<ChevronLeftIcon className="h-5 w-5 text-white" />}
          />
          <span className="px-2 py-3">{filters.page + 1}</span>
          <span>/</span>
          <span className="px-2 py-3">{totalPages}</span>
          <IconButton
            color="gray"
            disabled={filters.page === totalPages - 1}
            onClick={() => {
              setFilters({ ...filters, page: filters.page + 1 });
            }}
            icon={<ChevronRightIcon className="h-5 w-5 text-white" />}
          />
        </div>
      </div>
      {topupToApprove !== null && (
        <Modal
          title="Are you use that you want to approve this topup request?"
          onClose={() => setTopupToApprove(null)}
          action={approveRequest}
        />
      )}

      {topupToReject !== null && (
        <Modal
          title="Are you use that you want to reject this topup request?"
          content={
            <div className="flex">
              <input
                type="text"
                className="c4r__input-gray ml-3"
                placeholder="Reason..."
                value={rejecttionNote}
                onChange={event => {
                  setRejectionNote(event.target.value);
                }}
              />
            </div>
          }
          onClose={() => {
            setRejectionNote(null);
            setTopupToReject(null);
          }}
          action={rejecttionNote !== null ? rejectRequest : null}
        />
      )}
    </div>
  );
}
