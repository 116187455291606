import axios from 'axios';
import AuthService from './auth.service';

const instance = axios.create({
  baseURL: window.env.REWARDS_REACT_APP_API_SERVICE_URL,
});

instance.interceptors.request.use(
  config => {
    const token = AuthService.getTokenData();
    if (token) {
      config.headers['authorization'] = 'Bearer ' + token;
    }
    config.headers['x-local-app'] = window.env.REACT_APP_LUCKY_APP_ID;
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      AuthService.logout();
    } else {
      return Promise.reject(error.response.data);
    }
  },
);

export default instance;
