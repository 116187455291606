import React, { useState, useEffect } from 'react';
import AuthService from 'services/auth.service';

const AuthContext = React.createContext();
const useAuth = () => React.useContext(AuthContext);

const authStatus = Object.freeze({
  PENDING: 'pending_actions',
  SESSION: 'checking_session',
  READY: 'ready',
  ERROR: 'error',
});

function AuthProvider({ children }) {
  const [state, setState] = useState({
    status: authStatus.SESSION,
    error: null,
    user: null,
    permissions: [],
  });

  // CHECK USER SESSION
  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      const username = AuthService.getCurrentUsername();
      const permissions = AuthService.getCurrentUserRole();
      setState({
        status: authStatus.READY,
        error: null,
        user: { username },
        permissions: permissions,
      });
    } else {
      setState({ status: authStatus.READY, error: null, user: null });
    }
  }, []);

  // LOGIN HANDLER
  const login = googleToken => {
    setState({ ...state, status: authStatus.PENDING });
    AuthService.login(googleToken)
      .then(res => {
        AuthService.saveTokenData(res);
        const username = AuthService.getCurrentUsername();
        const permissions = AuthService.getCurrentUserRole();
        setState({
          status: authStatus.READY,
          error: null,
          user: { username },
          permissions: permissions,
        });
      })
      .catch(error => {
        setState({
          status: authStatus.READY,
          error,
          user: null,
          permissions: [],
        });
      });
  };

  // LOGOUT HANDLER
  const logout = () => {
    AuthService.logout();
    setState({ status: authStatus.READY, error: null, user: null });
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {state.status === authStatus.SESSION ? (
        <div className="bg-gray-300 text-black text-xl p-8">
          <pre>Loading...</pre>
        </div>
      ) : state.status === authStatus.ERROR ? (
        <div className="bg-error text-white text-xl p-8">
          <pre>
            Oops! Something went wrong. ERROR: {JSON.stringify(state.error)}
          </pre>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth, authStatus };
