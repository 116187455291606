import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CustomerService from 'services/customer.service';
import ProgramService from 'services/program.service';
import Button from 'components/shared/Button';
import { getPlacehoderImage, formatDate, formatCurrencyEGP } from 'common/utils';
import { spinner as LoaderIcon } from 'images/icons';

const CustomerTable = ({ history, maxProgramsDisplay = 1 }) => {
  //const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerPrograms, setCustomerPrograms] = useState({ state: 'loading', data: {} });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let customerPrograms = {};
    setLoading(true);
    CustomerService.getCustomers()
      .then((data) => {
        setCustomers(data);
        const programRequests = [];
        data.forEach((cus) => {
          customerPrograms[cus.id] = [];
          const req = ProgramService.getProgramsByCustomerId(cus.id);
          programRequests.push(req);
        });
        return Promise.all(programRequests);
      })
      .then((res) => {
        res.forEach((data) => {
          data.forEach((program) => {
            customerPrograms[program.customerId].push(program);
          });
        });
        setCustomerPrograms({ state: 'ready', data: customerPrograms });
      })
      .finally(() => setLoading(false));
  }, []);

  const onEditSelected = (id) => {
    history.push(`/customers/${id}`);
  };

  const onAddNew = () => {
    history.push('/customers/new');
  };

  // filter data table by search term
  const filteredCustomers = customers.filter((item) =>
    item.corporateName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="flex items-center justify-between px-4 py-1">
        <div className="flex w-64">
          <input
            type="search"
            className="c4r__input-gray"
            placeholder="Search..."
            value={searchTerm}
            onChange={(ev) => setSearchTerm(ev.currentTarget.value)}
          />
        </div>
        <div className="flex w-64">
          <Button onClick={onAddNew}>Add Customer</Button>
        </div>
      </div>
      <div className="p-4">
        <table className="min-w-full shadow-lg rounded-sm">
          <thead className="p-3">
            <tr className="bg-gray-300 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">
              <th className="px-6 py-4">Corporate Name</th>
              <th className="px-6 py-4">Sector</th>
              <th className="px-6 py-4">Rewards Programs</th>
              <th className="px-6 py-4">Total Balance (EGP)</th>
              <th className="px-6 py-4">Creation Date</th>
              <th className="px-6 py-4">More Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredCustomers.length === 0 && (
              <tr>
                <td className="px-6 py-3 font-bold text-gray-800 uppercase">
                  {customers.length === 0 && loading ? 'Loading...' : ''}
                  {customers.length === 0 && searchTerm !== '' ? 'No Results Found' : ''}
                </td>
              </tr>
            )}
            {filteredCustomers
              .sort((a, b) => a.corporateName - b.corporateName) // sorted alphabetically
              .map((customer, idx) => {
                return (
                  <tr key={idx}>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                      <div className="flex items-center space-x-3">
                        <div
                          className={`flex-shrink-0 w-3 h-3 rounded-full bg-${customer.active ? 'success' : 'error'}`}
                          aria-hidden="true"
                        />
                        <span className="truncate hover:text-primary-700">{customer.corporateName}</span>
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                      {customer.sectorType.name}
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-shrink-0 -space-x-1">
                          {(customerPrograms.state === 'loading' || !customerPrograms.data[customer.id]) && (
                            <span className="text-xs flex items-center">
                              <LoaderIcon height="30" width="30" />
                              Loading...
                            </span>
                          )}
                          {(customerPrograms.data[customer.id] || [])
                            .slice(0, maxProgramsDisplay)
                            .map((program, idx) => {
                              return (
                                <img
                                  key={idx}
                                  className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                  src={getPlacehoderImage(program.programType.name)}
                                  alt={program.programName}
                                />
                              );
                            })}
                        </div>
                        {(customerPrograms.data[customer.id] || []).length > maxProgramsDisplay && (
                          <span className="flex-shrink-0 text-xs leading-5 font-medium">
                            +{(customerPrograms.data[customer.id] || []).length - maxProgramsDisplay}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                      {(customerPrograms.state === 'loading' || !customerPrograms.data[customer.id]) && (
                        <div className="flex space-x-1">
                          <span className="text-xs flex items-center">
                            <LoaderIcon height="30" width="30" />
                            Loading...
                          </span>
                        </div>
                      )}
                      {customerPrograms.data[customer.id] &&
                        formatCurrencyEGP(
                          (customerPrograms.data[customer.id] || []).reduce(
                            (total, item) => total + item.wallet.balance.total,
                            0
                          )
                        )}
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(new Date(customer.created), 'dd MMM, yyyy')}
                    </td>
                    <td className="flex justify-center px-6 py-3">
                      <Button color="gray" onClick={() => onEditSelected(customer.id)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(CustomerTable);
