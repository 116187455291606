import { format, differenceInDays, differenceInMonths, addMonths } from 'date-fns';
import Papa from 'papaparse';

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_CHECKS = {
  length: 12,
  pattern: /2(010|011|012|015|013)\d{8}/,
}

export const getAppBuildEnvironmentVersion = () => {
  let appVersionEnv = '';
  if (window.env.REACT_APP_VERSION) {
    appVersionEnv += `v${window.env.REACT_APP_VERSION} `;
  }
  if (window.env.REACT_APP_ADMIN_ENV) {
    appVersionEnv += `(env: ${window.env.REACT_APP_ADMIN_ENV})`;
  }
  return appVersionEnv;
};

export const getPlacehoderImage = (text) => {
  const imgSrcUrl = `https://ui-avatars.com/api/?background=FBEB82&color=000&name=${text}&size=64`;
  return imgSrcUrl;
};

export const formatDate = (date, stringFormat) => {
  return format(date, stringFormat);
};

export const diffBetweenDates = (date1, date2) => {
  let res = differenceInDays(date1, date2) * -1;
  if (res === 0) {
    return 'today';
  }
  if (res > 30) {
    res = differenceInMonths(date1, date2) * -1;
    return `${res} months ago`;
  }
  return `${res} days ago`;
};

export const addMonthsToDate = (date, months) => {
  return addMonths(date, months);
};

export const formatCurrencyEGP = (amount) => {
  const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EGP' });
  return numberFormat.format(amount);
};

export const getProgramRewardsDuration = () => {
  return [
    {
      value: 2,
      label: '2 months',
    },
    {
      value: 6,
      label: '6 months',
    },
    {
      value: 12,
      label: '12 months',
    },
    {
      value: 24,
      label: '24 months',
    },
  ];
};

export const sanitizePhoneNumber = (number, length) => {
  let fullPhone = number.trim().replace('\r', '');
  // if the phone does not start with 2
  if (fullPhone.length === length-1) {
    fullPhone = '2' + fullPhone;
  }
  // if the phone does not start with 20
  if (fullPhone.length === length - 2) {
    fullPhone = '20' + fullPhone;
  }
  return fullPhone;
};

export const getRewardsTemplateFileFormat = () => {
  return [
    {
      fieldName: 'mobile_number',
      validate: (val) => {
        const fullPhone = sanitizePhoneNumber(new String(val), PHONE_CHECKS.length);
        const matchesExpectedLength = PHONE_CHECKS.length === fullPhone.length;
        const matchesExpectedFormat = PHONE_CHECKS.pattern.test(fullPhone);
        return matchesExpectedLength && matchesExpectedFormat
      },
    },
    {
      fieldName: 'amount_egp',
      validate: (val) => {
        const parsed = Number(val.trim());
        const isValid = !isNaN(parsed) && parsed > 0 && parsed <= 10000;
        return isValid;
      },
    },
  ];
};

export const generateDownloadableCSV = (data) => {
  const csv = Papa.unparse(data);
  const csvFile = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const filename = 'lucky_rewards_template.csv';
  // hack to autodownload file
  let link = window.document.createElement('a');
  link.href = window.URL.createObjectURL(csvFile);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const parseCSV = (file, fieldSettings) => {
  return new Promise((resolve) => {
    const parserResults = {
      data: [],
      valid: [],
      errors: [],
    };
    const parserSettings = {
      header: true,
      skipEmptyLines: true,
      step: (results, parser) => {
        if (results.errors && !results.errors.length) {
          const row = results.data;
          const rowErrors = [];
          const idx = Number(parserResults.data.length + 1); // row index
          delete row['']; // remove unmapped fields

          // iterate allowed fields
          fieldSettings.forEach((f) => {
            if (row.hasOwnProperty(f.fieldName)) {
              row[f.fieldName] = new String(row[f.fieldName]).trim();
            }
            if (!f.validate(row[f.fieldName])) {
              rowErrors.push(`Row ${idx}: invalid data '${row[f.fieldName]}' for '${f.fieldName}'`);
            }
          });
          if (rowErrors.length > 0) {
            parserResults.errors.push({ idx, ...row, rowErrors });
          } else {
            parserResults.valid.push({ idx, ...row });
          }
          parserResults.data.push({ idx, ...row });
        } else {
          parserResults.errors = parserResults.errors.concat(results.errors);
          parser.abort();
        }
      },
      beforeFirstChunk: (chunk) => {
        const rows = chunk.split(/\r\n|\r|\n/);
        const headers = rows[0].toLowerCase();
        const headersCleaned = [];
        headers.split(',').forEach((header) => {
          const acceptedHeaders = fieldSettings.map((header) => header.fieldName);
          const mappedHeader = acceptedHeaders.includes(header) ? header : '';
          headersCleaned.push(mappedHeader);
        });
        rows[0] = headersCleaned.join(',');
        return rows.slice().join('\n');
      },
      error: (error) => {
        console.error(error);
        parserResults.errors.push(JSON.stringify(error));
        resolve(parserResults);
      },
      complete: () => {
        resolve(parserResults);
      },
    };
    Papa.parse(file, parserSettings);
  });
};
