import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, withRouter } from 'react-router-dom';
import EnumService from 'services/valueset.service';
import CustomerTable from './CustomerTable';
import AddCustomerForm from './AddCustomerForm';
import EditCustomerForm from './EditCustomerForm';

const CustomerContainer = () => {
  const routeMatch = useRouteMatch();
  const [companySectors, setCompanySectors] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);

  useEffect(() => {
    EnumService.getCompanySectors().then((data) => {
      const dropdownOptions = data.map((item) => ({ value: item.id, label: item.name }));
      setCompanySectors(dropdownOptions);
    });
    EnumService.getProgramTypes().then((data) => {
      const dropdownOptions = data.map((item) => ({ value: item.id, label: item.name }));
      setProgramTypes(dropdownOptions);
    });
  }, []);

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${routeMatch.url}`}
          render={(props) => (
            <CustomerTable
              {...props}
              maxProgramsDisplay={3}
              companySectors={companySectors}
              programTypes={programTypes}
            />
          )}
        />
        <Route
          path={`${routeMatch.url}/new`}
          render={(props) => <AddCustomerForm {...props} companySectors={companySectors} programTypes={programTypes} />}
        />
        <Route
          path={`${routeMatch.url}/:customerId`}
          render={(props) => (
            <EditCustomerForm {...props} companySectors={companySectors} programTypes={programTypes} />
          )}
        />
      </Switch>
    </>
  );
};

export default withRouter(CustomerContainer);
