import React from 'react';
import { useAuth } from 'context/auth.context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MainPage from './components/Main';
import LoginPage from './components/auth/Login';
import './styles/index.css';
import AuthService from './services/auth.service';

function App() {
  const { user } = useAuth();
  const authUser = localStorage.getItem('c4r__authUser');
  const lastLogin = localStorage.getItem('c4r__lastLogin');
  if (
    authUser &&
    lastLogin &&
    !AuthService.isTokenActive(lastLogin, JSON.parse(authUser).expiresIn)
  ) {
    AuthService.logout();
  }
  return user ? (
    <MainPage />
  ) : (
    <GoogleOAuthProvider
      clientId={window.env.REACT_APP_GOOGLE_INTERNAL_CLIENT_ID}
    >
      <LoginPage />
    </GoogleOAuthProvider>
  );
}

export default App;
