import React from 'react';
import { error as ErrorIcon, success as SuccessIcon } from 'images/icons';

const AlertMessage = ({ type, title, message }) => {
  return (
    <div>
      <div className={`rounded-sm bg-gray-400 bg-${type} p-3`}>
        <div className="flex">
          <div className="flex-shrink-0 text-white">
            {type === 'success' && <SuccessIcon height={20} />}
            {type === 'error' && <ErrorIcon height={20} />}
          </div>
          <div className="ml-2">
            {title && <h3 className="text-sm font-bold text-white mb-1">{title}</h3>}
            <div className="text-sm text-white flex">
              {!message && type === 'success' && <span>Ok.</span>}
              {!message && type === 'error' && <span>Something went wrong.</span>}
              {message && <p className="mx-1">{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
