import React, { Component } from 'react';
import ReactSelect from 'react-select';
import luckyColors from 'styles/colorsPalette';

export default class Select extends Component {
  render() {
    return (
      <ReactSelect
        {...this.props}
        classNamePrefix="c4r"
        styles={{
          menu: () => ({
            border: 'none',
            backgroundColor: luckyColors.gray[200],
          }),
        }}
        theme={(theme) => ({
          ...theme,
          border: 'none',
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: luckyColors.gray[400],
            primary25: luckyColors.gray[300],
            primary50: luckyColors.gray[200],
            primary75: luckyColors.gray[100],
          },
        })}
      />
    );
  }
}
