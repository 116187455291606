// LUCKY BRANDING PALETTE https://www.figma.com/file/lTSLA8Q1iler8TWq4qF2qQ/UI-Kit---App?node-id=0%3A1
// eslint-disable-next-line
module.exports = {
  primary: {
    100: '#FEFBDD',
    200: '#FEF7BC',
    300: '#FDF19B',
    400: '#FBEB82',
    500: '#FAE159',
    600: '#FDD835',
    700: '#FBC02D',
    800: '#ECA73F',
    900: '#D29436',
  },
  gray: {
    100: '#FEFEFE',
    200: '#F7F9FA',
    300: '#EBF1F4',
    400: '#DCE5EB',
    500: '#9DB0BD',
    600: '#718897',
    700: '#3F505B',
    800: '#222D34',
    900: '#171B21',
  },
  success: '#2CD18A',
  error: '#FF585F',
};
