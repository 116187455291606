import axios from './axios.service';

export default class CustomerService {
  static getCustomers() {
    return axios.get('/api/v1/customers');
  }

  static getCustomer(id) {
    return axios.get(`/api/v1/customers/${id}`);
  }

  static createCustomer(data) {
    return axios.post('/api/v1/customers', data);
  }

  static updateCustomer(id, data) {
    return axios.put(`/api/v1/customers/${id}`, data);
  }
}
