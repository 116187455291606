import axios from './axios.service';

export default class ValueSetService {
  static getCompanySectors() {
    return axios.get('/api/v1/valuesets', { params: { enum: 'CorporateSector' } });
  }

  static getProgramTypes() {
    return axios.get('/api/v1/valuesets', { params: { enum: 'ProgramType' } });
  }

  static getNotificationTemplates() {
    return axios.get('/api/v1/valuesets', { params: { enum: 'NotificationTemplateName' } });
  }
}
