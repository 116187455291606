import React from 'react';
import { HashRouter } from 'react-router-dom';
import AdminContainer from './AdminContainer';

export default function Main() {
  return (
    <HashRouter>
      <AdminContainer />
    </HashRouter>
  );
}
