import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ProgramService from 'services/program.service';
import Select from 'components/shared/Select';
import Button from 'components/shared/Button';
import { getProgramRewardsDuration, EMAIL_REGEX } from 'common/utils';
import Modal from 'components/shared/Modal';
import AlertMessage from 'components/shared/AlertMessage';

function ProgramForm({ customerId, program, programTypes, onCancel, onSubmit }) {
  const { register, handleSubmit, control, reset, errors } = useForm();
  const [resendModal, setResendModal] = useState({ display: false, message: '', type: '' });

  const closeModal = () => {
    setResendModal({ display: false, message: '', type: '' });
  };

  const onSubmitProgram = (input) => {
    const req = {
      ...input,
      rewardsMonthDuration: input.rewardsMonthDuration.value || input.rewardsMonthDuration,
      programType: input.programType.value || input.programType,
    };
    if (program.id) {
      // PUT
      delete req.representatives;
      ProgramService.updateProgram(program.id, req)
        .then((data) => {
          alert('Rewards Program updated.');
          onSubmit();
        })
        .catch((error) => {
          alert(`ERROR: ${error.response?.data?.message || error.message || 'Something went wrong...'}`);
          onCancel();
        });
    } else {
      // POST
      ProgramService.addProgramToCustomer({ ...req, customerId })
        .then((data) => {
          alert('Rewards Program created.');
          onSubmit();
        })
        .catch((error) => {
          alert(`ERROR: ${error.response?.data?.message || error.message || 'Something went wrong...'}`);
          onCancel();
        });
    }
  };

  const resetRepresentativePassword = (email) => {
    ProgramService.resetRepresentativePassword(email)
      .then(() => {
        setResendModal({ display: true, message: 'A new invitation was sent to this representative', type: 'success' });
      })
      .catch(() => {
        setResendModal({ display: true, message: 'Could not reset representative password ', type: 'error' });
      });
  };

  return (
    <div>
      <div className="py-2">
        <form onSubmit={handleSubmit(onSubmitProgram)}>
          <div className="flex">
            <div className="w-1/2 px-8">
              <span className="c4r__input-label-gray">Program Name</span>
              <input
                name="programName"
                type="text"
                className="c4r__input-gray"
                placeholder={program.programName || 'Program Name'}
                ref={register({ required: true })}
                defaultValue={program.programName}
              />
              {errors.programName && (
                <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Enter a valid program name</span>
              )}
            </div>
            <div className="w-1/2 px-8">
              <span className="c4r__input-label-gray">Program Type</span>
              <Controller
                control={control}
                as={<Select className="c4r__input-gray" placeholder={program.programType?.name || 'Select'} />}
                name="programType"
                options={programTypes}
                rules={{ required: true }}
                defaultValue={program.programType?.id}
              />
              {errors.programType && (
                <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Select a program type</span>
              )}
            </div>
          </div>
          <div className="flex py-2">
            <div className="w-1/2 px-8" />
            <div className="w-1/2 px-8">
              <span className="c4r__input-label-gray">Rewards Duration (in months)</span>
              <Controller
                control={control}
                as={
                  <Select className="c4r__input-gray relative" placeholder={program.rewardsMonthDuration || 'Select'} />
                }
                name="rewardsMonthDuration"
                options={getProgramRewardsDuration()}
                rules={{ required: true }}
                defaultValue={program.rewardsMonthDuration || 24}
              />
              {errors.rewardsMonthDuration && (
                <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Select a period</span>
              )}
            </div>
          </div>
          <div className="space-y-4 p-4">
            <div className="flex w-full my-4 border-b-2 border-gray-300">
              <h3 className="text-gray-600 uppercase">Representative(s)</h3>
            </div>
            {program.representatives &&
              program.representatives.map((rep, idx) => {
                return (
                  <div key={idx} className="flex">
                    <div className="w-2/5 px-8">
                      <span className="c4r__input-label-gray">Full Name</span>
                      <input
                        name={`${idx}__representativeName`}
                        type="text"
                        className="c4r__input-gray"
                        readOnly={!!rep.accountHolderName}
                        disabled={true}
                        placeholder={rep.accountHolderName || 'Full Name'}
                        ref={register({ required: true })}
                        defaultValue={rep.accountHolderName}
                      />
                    </div>
                    <div className="w-2/5 px-8">
                      <span className="c4r__input-label-gray">E-mail Address</span>
                      <input
                        name={`${idx}__representativeEmail`}
                        type="email"
                        className="c4r__input-gray"
                        readOnly={!!rep.email}
                        disabled={true}
                        placeholder={rep.email}
                        ref={register({ required: true, pattern: EMAIL_REGEX })}
                        defaultValue={rep.email}
                      />
                    </div>
                    <div className="w-1/5 px-8 pt-6">
                      <Button
                        onClick={() => {
                          resetRepresentativePassword(rep.email);
                        }}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                );
              })}
            {!program.representatives && (
              <div className="flex">
                <div className="w-1/2 px-8">
                  <span className="c4r__input-label-gray">Full Name</span>
                  <input
                    name="representativeName"
                    type="text"
                    className="c4r__input-gray"
                    placeholder="Full Name"
                    ref={register({ required: true })}
                    defaultValue={program.representativeName}
                  />
                  {errors.representativeName && (
                    <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">
                      Enter a valid representative name
                    </span>
                  )}
                </div>
                <div className="w-1/2 px-8">
                  <span className="c4r__input-label-gray">E-mail Address</span>
                  <input
                    name="representativeEmail"
                    type="email"
                    className="c4r__input-gray"
                    placeholder="E-mail address"
                    ref={register({ required: true })}
                    defaultValue={program.representativeEmail}
                  />
                  {errors.representativeEmail && (
                    <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">
                      Enter a valid e-mail address
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex space-x-16 p-8">
            <Button color="gray" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="gray" onClick={reset}>
              Reset
            </Button>
            <Button type="submit">Save Changes</Button>
          </div>
        </form>
      </div>

      {resendModal.display && (
        <Modal
          title="Resend Password"
          content={
            <div>
              <AlertMessage type={resendModal.type} message={resendModal.message} />
            </div>
          }
          onClose={null}
          action={closeModal}
        />
      )}
    </div>
  );
}

export default ProgramForm;
