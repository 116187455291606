import axios from './axios.service';

export default class AuthService {
  static login(googleToken) {
    return axios.post('/api/v1/auth/admin/login', {
      googleToken,
    });
  }

  static isLoggedIn() {
    let isLoggedIn = false;
    const authUser = localStorage.getItem('c4r__authUser');
    const lastLogin = localStorage.getItem('c4r__lastLogin');

    if (!!authUser && !!lastLogin) {
      const d = Math.round(Date.now() / 1000);
      isLoggedIn =
        isLoggedIn && d - parseInt(lastLogin) < JSON.parse(authUser).expiresIn;
    }
    return isLoggedIn;
  }

  static isTokenActive(lastLogin, expiresIn) {
    const d = Math.round(Date.now() / 1000);
    return d - parseInt(lastLogin) < expiresIn;
  }

  static logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  static getTokenData(attribute = 'accessToken') {
    const authUser = localStorage.getItem('c4r__authUser');
    if (!authUser) {
      return null;
    }
    const authUserObj = JSON.parse(authUser);
    return authUserObj[attribute] || null;
  }

  static getCurrentUsername() {
    const jwt = this.getTokenData();
    const base64Payload = jwt.split('.')[1];
    const claimsObj = JSON.parse(atob(base64Payload));
    return claimsObj['email'] || null;
  }

  static getCurrentUserRole() {
    const jwt = this.getTokenData();
    const base64Payload = jwt.split('.')[1];
    const claimsObj = JSON.parse(atob(base64Payload));

    return claimsObj?.permissions || [];
  }

  static saveTokenData(data) {
    localStorage.setItem('c4r__authUser', JSON.stringify(data));
    localStorage.setItem('c4r__lastLogin', Math.round(Date.now() / 1000));
  }
}
