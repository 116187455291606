import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    id,
    name,
    color = 'primary',
    textColor = 'black',
    type = 'button',
    disabled = false,
    className = '',
    onClick,
    children,
  } = props;
  return (
    <>
      <div className="w-full px-1">
        <button
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          onClick={onClick}
          className={`w-full text-center px-8 py-1.5 border border-transparent shadow-sm text-sm font-semibold rounded-full text-${textColor} text-${textColor}-500 focus:outline-none
        ${disabled
              ? `cursor-not-allowed bg-gray-400 text-gray-500`
              : `bg-${color} bg-${color}-500 hover:bg-${color}-600 focus:bg-${color}-600`
            } ${className}`}
        >
          {children}
        </button>
      </div>
    </>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  textColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default Button;
