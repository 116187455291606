import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import CustomerService from 'services/customer.service';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import AlertMessage from 'components/shared/AlertMessage';
import Modal from 'components/shared/Modal';
import { getProgramRewardsDuration, EMAIL_REGEX } from 'common/utils';

const AddCustomerForm = ({ history, programTypes = [], companySectors = [] }) => {
  //const history = useHistory();
  const { register, handleSubmit, control, reset, errors } = useForm();
  const [confirmationModal, setConfirmationModal] = useState({ display: false });
  const [serviceResponse, setServiceResponse] = useState();

  const onSubmit = (form) => {
    const data = {
      ...form,
      sectorType: form.sectorType.value,
      programType: form.programType.value,
      rewardsMonthDuration: form.rewardsMonthDuration.value,
    };
    setConfirmationModal({ display: true, data });
  };

  const onConfirmModal = () => {
    const req = confirmationModal.data;
    CustomerService.createCustomer(req)
      .then((data) => {
        setConfirmationModal({ display: false });
        setServiceResponse({ error: null, message: 'Record saved successfully' });
        setTimeout(() => onReturn(), 1000); // redirect to customers table
      })
      .catch((error) => {
        setConfirmationModal({ display: false });
        setServiceResponse({ error });
      });
  };

  const onReturn = () => {
    history.push('/customers');
  };

  return (
    <div>
      <div className="flex items-center justify-start px-6 py-1">
        <div className="flex w-64">
          <Button color="gray" onClick={onReturn}>
            Go Back
          </Button>
        </div>
      </div>
      <div className="m-6 min-w-lg shadow-md rounded-sm">
        <div className="w-full bg-gray-300 text-gray-600 text-left text-lg px-6 py-4 uppercase">
          <h4>New Customer</h4>
        </div>
        <form className="p-8 space-y-8" onSubmit={handleSubmit(onSubmit)}>
          {serviceResponse && serviceResponse.error && (
            <AlertMessage type="error" message={serviceResponse.error.message} />
          )}
          {serviceResponse && !serviceResponse.error && (
            <AlertMessage type="success" message={serviceResponse.message} />
          )}
          <div className="space-y-4">
            <div className="flex w-full my-2 border-b-2 border-gray-300">
              <h3 className="text-gray-600 uppercase">Company Information</h3>
            </div>
            <div className="flex">
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Company Name</span>
                <input
                  name="corporateName"
                  type="text"
                  className="c4r__input-gray"
                  placeholder="Company Name"
                  ref={register({ required: true, minLength: 3, maxLength: 50 })}
                />
                {errors.corporateName && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Enter a valid name</span>
                )}
              </div>
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Company Sector</span>
                <Controller
                  control={control}
                  as={<Select className="c4r__input-gray" placeholder="Select a sector" />}
                  name="sectorType"
                  options={companySectors}
                  rules={{ required: true }}
                />
                {errors.sectorType && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Select a company sector</span>
                )}
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex w-full my-2 border-b-2 border-gray-300">
              <h3 className="text-gray-600 uppercase">Rewards Program</h3>
            </div>
            <div className="flex">
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Program Name</span>
                <input
                  name="programName"
                  type="text"
                  className="c4r__input-gray"
                  placeholder="Program Name"
                  ref={register({ required: true, minLength: 3, maxLength: 50 })}
                />
                {errors.programName && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Enter a valid program name</span>
                )}
              </div>
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Program Type</span>
                <Controller
                  control={control}
                  as={<Select className="c4r__input-gray" placeholder="Select a type" />}
                  name="programType"
                  options={programTypes}
                  rules={{ required: true }}
                />
                {errors.programType && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Select a program type</span>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2 px-8" />
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Rewards Duration (in months)</span>
                <Controller
                  control={control}
                  as={<Select className="c4r__input-gray relative" placeholder="Select period" />}
                  name="rewardsMonthDuration"
                  options={getProgramRewardsDuration()}
                  rules={{ required: true }}
                  defaultValue={24}
                />
                {errors.rewardsMonthDuration && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Select a period</span>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Representative Name</span>
                <input
                  name="representativeName"
                  type="text"
                  className="c4r__input-gray"
                  placeholder="Account Holder Name"
                  ref={register({ required: true, minLength: 3, maxLength: 50 })}
                />
                {errors.representativeName && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">
                    Enter a valid representative name
                  </span>
                )}
              </div>
              <div className="w-1/2 px-8">
                <span className="c4r__input-label-gray">Representative E-mail Address</span>
                <input
                  name="representativeEmail"
                  type="email"
                  className="c4r__input-gray"
                  placeholder="Account Holder e-mail address"
                  ref={register({ required: true, pattern: EMAIL_REGEX })}
                />
                {errors.representativeEmail && (
                  <span className="bg-error text-white text-sm mb-1 p-2 flex w-full">Enter a valid e-mail address</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex space-x-24 p-8">
            <Button color="gray" onClick={reset}>
              Reset
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </form>
      </div>
      {confirmationModal.display && (
        <Modal
          title="Confirmation to Invite Customer"
          content={<p>Do you want to send invite to {confirmationModal.data.representativeEmail} ?</p>}
          onClose={() => setConfirmationModal({ display: false })}
          action={confirmationModal.data ? onConfirmModal : null}
        />
      )}
    </div>
  );
};

export default withRouter(AddCustomerForm);
