import React, { useState } from 'react';
import Button from 'components/shared/Button';
import { spinner as LoaderIcon } from 'images/icons';

const Modal = ({ title, content, onClose, action }) => {
  const [loading, setLoading] = useState(false);

  const handleAction = () => {
    setLoading(true);
    action();
  };
  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="m-1 p-1">
                <h3 className="text-xl leading-6 font-bold text-gray-900 px-2" id="modal-headline">
                  {title}
                </h3>
                <div className="mt-2">
                  <div className="text-sm text-gray-500">{content}</div>
                </div>
              </div>
            </div>
            <div className={`mt-5 sm:grid sm:grid-cols-${onClose ? 2 : 1} sm:gap-3 sm:grid-flow-row-dense`}>
              {onClose && (
                <Button color="gray" onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
              )}
              <Button onClick={handleAction} disabled={!action || loading}>
                {loading ? (
                  <div className="flex text-center">
                    <span className="flex items-center space-x-2">
                      <LoaderIcon height="20" width="20" />
                      Loading...
                    </span>
                  </div>
                ) : (
                  <span>Ok</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
