import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/auth.context';
import SidebarMenu from './shared/SidebarMenu';
import CustomerContainer from './customers/Container';
import SettingsContainer from './settings/Container';
import TopupContainer from './topup/Container';

export default function AdminContainer() {
  const { pathname } = useLocation();
  const { permissions } = useAuth();

  const currentPage = pathname.split('/')[1];

  let fallbackRoute = '';
  if (permissions.includes('manage-customers')) fallbackRoute = '/customers';
  else if (permissions.includes('manage-topup-requests'))
    fallbackRoute = '/topups';

  return (
    <>
      <div className="h-screen flex overflow-x-hidden bg-white">
        <SidebarMenu />
        <div className="flex flex-col w-full flex-1">
          <main>
            <div className="border-b border-gray-300 py-4 flex items-center justify-between px-8">
              <div className="flex-1 min-w-0">
                <h1 className="text-lg font-bold leading-6 text-gray-900 capitalize">
                  {currentPage}
                </h1>
              </div>
            </div>
            <div className="p-4">
              <Switch>
                {permissions.includes('manage-app-settings') && (
                  <Route path="/settings" component={SettingsContainer} />
                )}
                {permissions.includes('manage-customers') && (
                  <Route path="/customers" component={CustomerContainer} />
                )}

                <Route path="/topups" component={TopupContainer} />

                <Route path="*">
                  <Redirect to={fallbackRoute} />
                </Route>
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
