import React, { useState, useEffect } from 'react';
import EnumService from 'services/valueset.service';
import AlertMessage from 'components/shared/AlertMessage';
import { message as MessageIcon } from 'images/icons';

export default function SettingsContainer() {
  const [smsSettings, setSmsSettings] = useState();

  useEffect(() => {
    // TODO: enhance ADMIN settings
    EnumService.getNotificationTemplates().then((data) => {
      setSmsSettings(data); // these templates should be editable
    });
  }, []);

  if (smsSettings) {
    return (
      <>
        <div className="w-full shadow-lg rounded-sm p-4">
          {smsSettings.map((valueSet) => {
            return (
              <div className="my-4 p-6 border-2 border-gray-300 border-dashed rounded-lg">
                <div className="flex">
                  <MessageIcon height="20" width="20" />
                  <span className="font-bold mx-2 text-gray-800">{valueSet.id}:</span>
                  <p className="text-sm text-gray-700 whitespace-pre-wrap text-right">{valueSet.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return <AlertMessage type="default" message="Loading SMS templates..." />;
}
