import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useAuth } from 'context/auth.context';
import { getAppBuildEnvironmentVersion } from 'common/utils';
import C4RLogo from 'images/logo_v2.png';
import * as C4RSvgIcons from 'images/icons';

const SidebarMenu = ({ history }) => {
  const { pathname } = useLocation();
  //const history = useHistory();
  const { user, logout, permissions } = useAuth();

  return (
    <>
      <div className="flex-shrink-0">
        <div className="h-full flex flex-col w-64 border-r border-gray-300 py-4 bg-gray-200">
          <div className="flex items-center px-6">
            <img
              className="h-10 w-auto"
              src={C4RLogo}
              alt="Lucky Connect 4 Rewards"
            />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <div className="p-3 relative inline-block text-left">
              <span className="flex flex-wrap hover:bg-gray-300 p-2">
                <span className="w-full text-gray-900 text-sm font-medium truncate">
                  ADMINISTRATOR
                </span>
                <span className="w-full text-gray-500 text-sm truncate">
                  {user.username}
                </span>
              </span>
            </div>
            <nav className="px-3 mt-6">
              <div className="space-y-1">
                {permissions.includes('manage-customers') ? (
                  <SidebarMenuOption
                    label="customers"
                    icon="user"
                    isSelected={pathname.startsWith('/customers')}
                    action={() => history.push('/customers')}
                  />
                ) : (
                  <></>
                )}
                {permissions.includes('manage-app-settings') ? (
                  <SidebarMenuOption
                    label="settings"
                    icon="info"
                    isSelected={pathname.startsWith('/settings')}
                    action={() => history.push('/settings')}
                  />
                ) : (
                  <></>
                )}
                {permissions.includes('manage-topup-requests') ? (
                  <SidebarMenuOption
                    label="topups"
                    icon="topup"
                    isSelected={pathname.startsWith('/topups')}
                    action={() => history.push('/topups')}
                  />
                ) : (
                  <></>
                )}
                {/* add more menu options here... */}
                <SidebarMenuOption
                  label="logout"
                  icon="logout"
                  action={logout}
                />
              </div>
              <div className="text-gray-500 hover:bg-gray-200 text-sm mt-16">
                <span>App Build {getAppBuildEnvironmentVersion()}</span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

// Child pure component
const SidebarMenuOption = ({ label, icon, isSelected, action }) => {
  const Icon = C4RSvgIcons[icon];
  return (
    <button
      className={`w-full bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md focus:outline-none hover:bg-primary-200 ${
        isSelected ? 'bg-primary-400' : ''
      }`}
      onClick={action}
    >
      <Icon width="24" height="24" />
      <span className="ml-3 capitalize">{label}</span>
    </button>
  );
};

export default withRouter(SidebarMenu);
