import axios from './axios.service';

export default class TopupService {
  static list(options) {
    return axios.get('/api/v1/topups/admin/all', {
      params: options,
    });
  }

  static listMethods() {
    return axios.get('/api/v1/topups/admin/methods');
  }
  static approve(id) {
    return axios.put(`/api/v1/topups/admin/${id}/approved`);
  }

  static reject(id, note) {
    return axios.put(`/api/v1/topups/admin/${id}/rejected`, { note });
  }
}
