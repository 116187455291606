import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import CustomerService from 'services/customer.service';
import ProgramService from 'services/program.service';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import AlertMessage from 'components/shared/AlertMessage';
import ProgramDetails from './ProgramDetails';
import ProgramForm from './ProgramForm';
import { formatCurrencyEGP } from 'common/utils';
import LoaderGIF from 'images/icons/loading.gif';

const EditCustomerForm = ({ history, programTypes = [], companySectors = [] }) => {
  const { customerId } = useParams();
  //const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const [changes, setChanges] = useState(0);
  const [refreshingWallet, setRefreshingWallet] = useState(false);
  const [customer, setCustomer] = useState();
  const [selectedEditProgram, setSelectedEditProgram] = useState();
  const [newProgram, setNewProgram] = useState();
  const [serviceResponse, setServiceResponse] = useState();

  useEffect(() => {
    let customerDetails;
    CustomerService.getCustomer(customerId) // by id
      .then((data) => {
        customerDetails = data;
        return ProgramService.getProgramsByCustomerId(customerId);
      })
      .then((data) => {
        customerDetails.programs = data;
        setCustomer(customerDetails);
      })
      .catch((error) => {
        setServiceResponse({ error });
      });
  }, [customerId, changes]);

  useEffect(() => {
    const programWalletsRefresh = setInterval(() => {
      setRefreshingWallet(true);
      ProgramService.getProgramsByCustomerId(customerId).then((data) => {
        setCustomer({ ...customer, programs: data });
        setRefreshingWallet(false);
      });
    }, 60000); // refresh every minute
    return () => clearInterval(programWalletsRefresh);
  }, [customer, customerId]);

  const onSubmitCustomer = ({ corporateName, sectorType }) => {
    CustomerService.updateCustomer(customerId, { corporateName, sectorType: sectorType.value })
      .then((data) => {
        setServiceResponse({ error: null, message: 'Record updated successfully', data });
      })
      .catch((error) => {
        setServiceResponse({ error });
      })
      .finally(() => onReload());
  };

  const onToggleCustomerStatus = () => {
    const req = {
      active: !customer.active,
    };
    CustomerService.updateCustomer(customerId, req)
      .then((data) => {
        setServiceResponse({ error: null, message: 'Record updated', data });
      })
      .catch((error) => {
        setServiceResponse({ error });
      })
      .finally(() => onReload());
  };

  const onReload = () => {
    setNewProgram(null);
    setTimeout(() => {
      setServiceResponse(null);
      const newChanges = changes + 1;
      setChanges(newChanges);
    }, 2000);
  };

  const onAddProgram = () => {
    // empty default values to populate form
    setNewProgram({
      programName: '',
      programType: '',
      rewardsMonthDuration: 24,
      representativeName: '',
      representativeEmail: '',
    });
  };

  const onReturn = () => {
    history.push('/customers');
  };

  const totalCorporateWalletBalance = (customer?.programs || []).reduce(
    (total, program) => total + program.wallet.balance.total,
    0
  );

  return (
    <div>
      <div className="flex items-center justify-start px-6 py-1">
        <div className="flex w-64">
          <Button color="gray" onClick={onReturn}>
            Go Back
          </Button>
        </div>
      </div>

      {!customer && !serviceResponse && (
        <div className="m-6 min-w-lg shadow-md rounded-sm">
          <div className="w-full bg-gray-200 text-gray-600 text-left text-lg px-6 py-4 capitalize flex items-center">
            <img src={LoaderGIF} alt="loading" className="h-16" />
            <h3>Loading Customer Information...</h3>
          </div>
        </div>
      )}
      {serviceResponse && serviceResponse.error && (
        <AlertMessage type="error" message={serviceResponse.error.message} />
      )}

      {customerId && customer && (
        <div className="m-6 min-w-lg shadow-md rounded-sm">
          <div className="flex justify-between w-full bg-gray-200 text-gray-600 text-left text-lg px-6 py-4 uppercase">
            <div className="flex items-center">
              <h4>Edit Customer {customer.corporateName}</h4>
              <div
                className={`mx-2 w-3 h-3 rounded-full bg-${customer.active ? 'success' : 'error'}`}
                aria-hidden="true"
              />
            </div>
            <div className="w-48">
              <Button color={customer.active ? 'error' : 'success'} textColor="white" onClick={onToggleCustomerStatus}>
                {customer.active ? 'Disable Customer' : 'Enable Customer'}
              </Button>
            </div>
          </div>
          <div className="p-8 space-y-8">
            {serviceResponse && serviceResponse.error && (
              <AlertMessage type="error" message={serviceResponse.error.message} />
            )}
            {serviceResponse && !serviceResponse.error && (
              <AlertMessage type="success" message={serviceResponse.message} />
            )}
            <div id="company-information-form">
              <form onSubmit={handleSubmit(onSubmitCustomer)}>
                <div className="flex w-full my-2 border-b-2 border-gray-300">
                  <h3 className="text-gray-600 uppercase">Company Information</h3>
                </div>
                <div className="flex items-center my-4">
                  <div className="w-1/3 px-6">
                    <span className="c4r__input-label-gray">Company Name</span>
                    <input
                      name="corporateName"
                      type="text"
                      className="c4r__input-gray"
                      placeholder={customer.corporateName}
                      ref={register({ required: true })}
                      defaultValue={customer.corporateName}
                    />
                  </div>
                  <div className="w-1/3 px-6">
                    <span className="c4r__input-label-gray">Company Sector</span>
                    <Controller
                      control={control}
                      as={<Select className="c4r__input-gray" placeholder={customer.sectorType?.name} />}
                      name="sectorType"
                      options={companySectors}
                      rules={{ required: true }}
                      defaultValue={customer.sectorType?.id}
                    />
                  </div>
                  <div className="w-1/3 px-6">
                    <Button type="submit" disabled={!isDirty || !isValid || isSubmitting}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="space-y-4">
              <div className="flex w-full my-2 border-b-2 border-gray-300">
                <h3 className="text-gray-600 uppercase">Rewards Programs</h3>
              </div>
              <div className="space-y-2">
                {(customer.programs || [])
                  .sort((a, b) => b.created - a.created) // recently created ones first
                  .map((program, idx) => {
                    // editable programs
                    return (
                      <ProgramDetails
                        key={idx}
                        customerId={customerId}
                        enabled={customer.active}
                        program={program}
                        programTypes={programTypes}
                        selected={selectedEditProgram}
                        onSelect={setSelectedEditProgram}
                        refreshingWallet={refreshingWallet}
                      />
                    );
                  })}
              </div>
            </div>
            {/* add new programs */}
            {newProgram && (
              <div className="p-2 border-2 border-primary-500 rounded-lg">
                <ProgramForm
                  customerId={customerId}
                  program={newProgram}
                  programTypes={programTypes}
                  onCancel={() => setNewProgram(null)}
                  onSubmit={onReload}
                />
              </div>
            )}
            {!newProgram && (
              <div className="grid grid-cols-5 gap-10 px-6">
                <div className="col-start-4 px-4 py-2 bg-gray-300 rounded-lg text-gray-700">
                  <span className="font-bold">Total Corporate Balance ($): </span>
                  <span className={refreshingWallet || totalCorporateWalletBalance === 0 ? 'text-error' : ''}>
                    {refreshingWallet ? 'Refreshing...' : formatCurrencyEGP(totalCorporateWalletBalance)}
                  </span>
                </div>
                <div>
                  <Button onClick={onAddProgram}>Add Program</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(EditCustomerForm);
