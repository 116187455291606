import React, { useState, useEffect } from 'react';
import ProgramService from 'services/program.service';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import AlertMessage from 'components/shared/AlertMessage';
import ProgramForm from './ProgramForm';
import {
  getPlacehoderImage,
  formatDate,
  diffBetweenDates,
  formatCurrencyEGP,
  parseCSV,
  getRewardsTemplateFileFormat,
  generateDownloadableCSV,
} from 'common/utils';
import { spinner as LoaderIcon } from 'images/icons';

function ProgramDetails({
  customerId,
  enabled,
  program,
  programTypes = [],
  selected = {},
  onSelect,
  refreshingWallet = false,
}) {
  const [changes, setChanges] = useState(0);
  const [programDetails, setProgramDetails] = useState({ ...program });
  const [openModalNewRepresentative, setOpenModalNewRepresentative] = useState({ display: false });
  const [openModalFunds, setOpenModalFunds] = useState({ display: false });
  const [openModalRewards, setOpenModalRewards] = useState({ display: false });

  useEffect(() => {
    ProgramService.getProgramById(program.id).then((data) => {
      setProgramDetails(data);
    });
  }, [program, changes, refreshingWallet]);

  const onChangeFunds = () => {
    ProgramService.changeFundsToProgram(programDetails.id, openModalFunds.amount)
      .then((data) => {
        console.info(data);
        setOpenModalFunds({ ...openModalFunds, error: null, result: 'DONE! Program Wallet updated.' });
      })
      .catch((error) => {
        console.error(error);
        setOpenModalFunds({
          ...openModalFunds,
          result: null,
          error: 'ERROR! Cannot change funds to Program Wallet.',
        });
      })
      .finally(() => onReload());
  };

  const onAddRepresentative = () => {
    const rep = {
      representativeName: openModalNewRepresentative.representativeName,
      representativeEmail: openModalNewRepresentative.representativeEmail,
    };
    ProgramService.addRepresentative(programDetails.id, rep)
      .then((data) => {
        console.info(data);
        setOpenModalNewRepresentative({
          ...openModalNewRepresentative,
          error: null,
          result: 'New representative invited!',
        });
      })
      .catch((error) => {
        console.error(error);
        setOpenModalNewRepresentative({
          ...openModalNewRepresentative,
          result: null,
          error: 'ERROR! Cannot add representative.',
        });
      })
      .finally(() => onReload());
  };

  const onSelectFile = (file) => {
    console.log({ file });
    if (!file) {
      setOpenModalRewards({ ...openModalRewards, result: null, error: 'Please select a file to proceed.' });
    } else if (file.size > 5000000) {
      // Max allowed 5MB = ~10,000 CSV rows
      setOpenModalRewards({ ...openModalRewards, result: null, error: `ERROR! File ${file.name} is too big.` });
    } else {
      const fileSettings = getRewardsTemplateFileFormat();
      parseCSV(file, fileSettings).then((res) => {
        console.log({ res });
        /* VALIDATED ON BACKEND --> IF contains valid rows should try to process them
        if (res.errors.length > 0) {
          setOpenModalRewards({
            ...openModalRewards,
            fileName: file.name,
            error: `Your file contains ${res.data.length} rows, but ${res.errors.length} have errors. Please check.`,
            result: null,
          });
        }
        */
        const sumRewardsAmount = res.data.reduce(
          (total, row) => total + (!isNaN(row.amount_egp) ? Number(row.amount_egp) : 0),
          0
        );
        if (
          (res.data.length === res.errors.length && res.valid.length === 0) ||
          (res.data.length === 0 && res.errors.length > 0) ||
          sumRewardsAmount === 0
        ) {
          setOpenModalRewards({
            ...openModalRewards,
            fileName: file.name,
            error: `Something went wrong. Please check the file and try again.`,
            result: null,
          });
        } else if (sumRewardsAmount <= programDetails.wallet.balance.total) {
          setOpenModalRewards({
            ...openModalRewards,
            fileName: file.name,
            fileData: res.data,
            //result: `Your file contains ${res.valid.length} rows with a valid format. Please confirm to proceed.`,
            result: 'Please confirm to proceed.',
            error: null,
          });
        } else {
          setOpenModalRewards({
            ...openModalRewards,
            fileName: file.name,
            error: `The total amount of rewards (${formatCurrencyEGP(
              sumRewardsAmount
            )}) cannot exceed wallet balance (${formatCurrencyEGP(programDetails.wallet.balance.total)}).`,
            result: null,
          });
        }
      });
    }
  };

  const onUploadCSV = () => {
    const plainValidJsonData = openModalRewards.fileData;
    ProgramService.grantRewards(programDetails.id, plainValidJsonData)
      .then((data) => {
        console.info(data);
        setOpenModalRewards({
          ...openModalRewards,
          error: null,
          result: `Your request (Job #${data.data.job}) is being processed, we will notify the results via email.`,
        });
        alert(data.message);
      })
      .catch((error) => {
        console.error(error);
        setOpenModalRewards({ ...openModalRewards, result: null, error: 'Something went wrong. Try again later.' });
        alert(`ERROR: ${error.response?.data?.message || error.message || 'Something went wrong...'}`);
      })
      .finally(() => onReload());
  };

  const onReload = () => {
    const newChanges = changes + 1;
    setChanges(newChanges);
    setTimeout(() => {
      // close modals and reload
      setOpenModalRewards({ display: false });
      setOpenModalFunds({ display: false });
      setOpenModalNewRepresentative({ display: false });
      onSelect({});
    }, 3000);
  };

  const onDownloadTemplateFile = () => {
    const templateData = [
      {
        mobile_number: '01011111111',
        amount_egp: 0,
      },
      {
        mobile_number: '01122222222',
        amount_egp: 300,
      },
      {
        mobile_number: '01233333333',
        amount_egp: 1200,
      },
      {
        mobile_number: '01544444444',
        amount_egp: 10000,
      },
    ];
    generateDownloadableCSV(templateData);
  };

  return (
    <div>
      <div
        className={`w-full p-4 border-2 border-gray-300 rounded-lg ${
          selected.id === program.id ? 'border-primary-500' : ''
        }`}
      >
        {!programDetails && (
          <div className="flex">
            <span className="flex items-center space-x-2">
              <LoaderIcon height="40" width="40" />
              Loading Program...
            </span>
          </div>
        )}

        {/* VIEW INFORMATION */}
        {selected.id !== program.id && programDetails && (
          <div className="grid items-center grid-cols-5 gap-8 text-gray-700">
            <div className="flex items-center space-x-2">
              <div>
                <img
                  className="h-12 w-12 rounded-full ring-2 ring-white"
                  src={getPlacehoderImage(programDetails.programType?.name)}
                  alt={programDetails.programName}
                />
              </div>
              <div>
                <span className="font-medium">{programDetails.programName}</span>
                <span className="block text-xs text-gray-500">{programDetails.programType?.name}</span>
              </div>
            </div>
            <div>
              <span>Rewards expire in {programDetails.rewardsMonthDuration} months</span>
              <span className="block text-xs text-gray-500">
                Program created at: {formatDate(new Date(programDetails.created), 'dd MMM, yyyy')}
              </span>
            </div>
            <div>
              <span className="">
                {!programDetails.representatives ? '' : programDetails.representatives.length} Representative(s)
              </span>
              <div className="space-y-1">
                {!programDetails.representatives && <span className="flex text-xs text-gray-500">Loading...</span>}
                {(programDetails.representatives || []).map((rep, idx) => {
                  return (
                    <span key={idx} className="flex text-xs text-gray-500">
                      {rep.accountHolderName}
                    </span>
                  );
                })}
              </div>
            </div>
            <div>
              <div>
                Wallet Balance ($){' '}
                <span className={refreshingWallet || programDetails.wallet.balance.total === 0 ? 'text-error' : ''}>
                  {refreshingWallet ? 'Refreshing...' : formatCurrencyEGP(programDetails.wallet.balance.total)}
                </span>
              </div>
              {!programDetails.transactions && <span className="block text-xs text-gray-500">Loading...</span>}
              {programDetails.transactions && (
                <div>
                  <div className="block text-xs text-gray-500">
                    {programDetails.transactions.length} transactions
                    {programDetails.transactions.length > 0 && (
                      <span className="ml-2">
                        (Last update {diffBetweenDates(new Date(programDetails.transactions[0].updated), new Date())})
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="space-y-1">
              <Button color="gray" onClick={() => onSelect(programDetails)}>
                View More / Edit
              </Button>
              <Button onClick={() => setOpenModalFunds({ display: true, amount: 0, confirmation: 0 })}>
                Add/Deduct Funds
              </Button>
              <Button
                onClick={() =>
                  setOpenModalNewRepresentative({ display: true, representativeName: '', representativeEmail: '' })
                }
              >
                Invite Representative
              </Button>
              <Button disabled={!enabled} onClick={() => setOpenModalRewards({ display: true, file: null })}>
                Grant Rewards
              </Button>
            </div>
          </div>
        )}

        {/* EDIT INFORMATION */}
        {selected.id === program.id && programDetails && (
          <ProgramForm
            customerId={customerId}
            program={selected}
            programTypes={programTypes}
            onCancel={() => onSelect({})}
            onSubmit={onReload}
          />
        )}
      </div>

      {/* CONFIRMATION MODALS */}
      {openModalFunds.display && (
        <Modal
          title="Change Wallet Funds"
          content={
            <div>
              {!openModalFunds.error && openModalFunds.result && (
                <AlertMessage type="success" message={openModalFunds.result} />
              )}
              {openModalFunds.error && <AlertMessage type="error" message={openModalFunds.error} />}
              <div className="w-full p-2">
                <span className="c4r__input-label-gray">Amount (EGP)</span>
                <input
                  type="number"
                  className="c4r__input-gray"
                  placeholder="Enter $ amount in EGP"
                  value={openModalFunds.amount}
                  onChange={(e) =>
                    setOpenModalFunds({
                      display: true,
                      amount: e.currentTarget.value,
                      confirmation: openModalFunds.confirmation,
                    })
                  }
                />
              </div>
              <div className="w-full p-2">
                <span className="c4r__input-label-gray">Confirm Amount (EGP)</span>
                <input
                  type="number"
                  className="c4r__input-gray"
                  placeholder="Confirm $ amount in EGP"
                  value={openModalFunds.confirmation}
                  onChange={(e) =>
                    setOpenModalFunds({
                      display: true,
                      confirmation: e.currentTarget.value,
                      amount: openModalFunds.amount,
                    })
                  }
                />
              </div>
            </div>
          }
          onClose={() => setOpenModalFunds({ display: false })}
          action={openModalFunds.amount && openModalFunds.confirmation === openModalFunds.amount ? onChangeFunds : null}
        />
      )}

      {openModalNewRepresentative.display && (
        <Modal
          title={`Invite new Representative to Program ${programDetails.programName}`}
          content={
            <div>
              {!openModalNewRepresentative.error && openModalNewRepresentative.result && (
                <AlertMessage type="success" message={openModalNewRepresentative.result} />
              )}
              {openModalNewRepresentative.error && (
                <AlertMessage type="error" message={openModalNewRepresentative.error} />
              )}
              <div className="w-full p-2">
                <span className="c4r__input-label-gray">Full Name</span>
                <input
                  type="text"
                  className="c4r__input-gray"
                  placeholder="Account holder name"
                  value={openModalNewRepresentative.representativeName}
                  onChange={(e) =>
                    setOpenModalNewRepresentative({
                      ...openModalNewRepresentative,
                      representativeName: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="w-full p-2">
                <span className="c4r__input-label-gray">E-mail address</span>
                <input
                  type="email"
                  className="c4r__input-gray"
                  placeholder="Email"
                  value={openModalNewRepresentative.representativeEmail}
                  onChange={(e) =>
                    setOpenModalNewRepresentative({
                      ...openModalNewRepresentative,
                      representativeEmail: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
          }
          onClose={() => setOpenModalNewRepresentative({ display: false })}
          action={
            openModalNewRepresentative.representativeName && openModalNewRepresentative.representativeEmail
              ? onAddRepresentative
              : null
          }
        />
      )}

      {openModalRewards.display && (
        <Modal
          title="Grant Rewards"
          content={
            <div>
              {!openModalRewards.error && openModalRewards.result && (
                <AlertMessage type="success" message={openModalRewards.result} />
              )}
              {openModalRewards.error && <AlertMessage type="error" message={openModalRewards.error} />}
              <div className="p-2">
                <div className="flex justify-center p-5 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-700">
                      <label className="relative cursor-pointer bg-white rounded-md font-bold text-primary-600 hover:text-primary-700">
                        <span>{openModalRewards.fileName ? 'Choose another file' : 'Upload a file'}</span>
                        <input
                          type="file"
                          accept=".csv"
                          className="sr-only"
                          onChange={(e) => onSelectFile(e.target.files[0])}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    {!openModalRewards.fileName && (
                      <div>
                        <p className="text-xs text-gray-500">CSV up to 5MB</p>
                        <p className="text-xs text-gray-500">(Max amount is EGP 10,000 per row)</p>
                        <a
                          className="text-xs text-primary-600 underline"
                          href="#csv_template"
                          onClick={onDownloadTemplateFile}
                        >
                          Download Template
                        </a>
                      </div>
                    )}
                    {openModalRewards.fileName && (
                      <p className="text-sm font-bold text-gray-600">{openModalRewards.fileName}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          onClose={() => setOpenModalRewards({ display: false })}
          action={openModalRewards.fileData ? onUploadCSV : null}
        />
      )}
    </div>
  );
}

export default ProgramDetails;
