import React from 'react';
import { useAuth, authStatus } from 'context/auth.context.js';

import { getAppBuildEnvironmentVersion } from 'common/utils';
import C4RLogo from 'images/logo_v2.png';

import { GoogleLogin } from '@react-oauth/google';

export default function Login() {
  const { status, error, user, login } = useAuth();
  const isLoading = status === authStatus.PENDING;

  const onSubmit = googleToken => {
    login(googleToken);
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-primary-200 py-12 px-6">
        <div className="relative max-w-lg w-full space-y-8 p-12 bg-gray-200">
          <div>
            <img
              className="mx-auto h-10 w-auto"
              src={C4RLogo}
              alt="Lucky Connect Rewards"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in as Administrator
            </h2>
          </div>

          <div className="flex items-center justify-center">
            <GoogleLogin
              onSuccess={res => {
                onSubmit(res.credential);
              }}
              onError={() => {
                console.log('Google login failed');
              }}
              width="270"
            />
          </div>

          <div>
            {error && !user && (
              <span className="text-error text-sm p-3 flex w-full">
                {error.message}
              </span>
            )}
          </div>

          <div className="absolute bottom-0 right-0 text-sm text-gray-500 p-2">
            {getAppBuildEnvironmentVersion()}
          </div>
        </div>
      </div>
    </>
  );
}
