import axios from './axios.service';

export default class ProgramService {
  static getProgramsByCustomerId(customerId) {
    return axios.get('/api/v1/programs', { params: { customerId } });
  }

  static getProgramById(id) {
    return axios.get(`/api/v1/programs/${id}`);
  }

  static addProgramToCustomer(data) {
    return axios.post('/api/v1/programs', data);
  }

  static updateProgram(id, data) {
    return axios.put(`/api/v1/programs/${id}`, data);
  }

  static addRepresentative(programId, { representativeName, representativeEmail }) {
    return axios.post(`/api/v1/programs/${programId}/add_representatives`, {
      representativeName,
      representativeEmail,
    });
  }

  static resetRepresentativePassword(email) {

    return axios.post(`/api/v1/programs/representatives/password/${email}`);
  }

  static changeFundsToProgram(programId, amount) {
    return axios.post(`/api/v1/programs/${programId}/change_funds`, { amount: Number(amount) });
  }

  static grantRewards(programId, data) {
    return axios.post(`/api/v1/programs/${programId}/grant_admin_rewards`, { data });
  }
}
